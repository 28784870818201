'use client';

import { Root, Field, Label, Control, Submit } from '@radix-ui/react-form';
import { Root as CheckboxRoot, Indicator as CheckboxIndicator } from '@radix-ui/react-checkbox';
import styles from '../form.styles.module.css';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TAuthFormAction } from '../EntryForm';
import classNames from 'classnames';
import { Loader } from '@/components/Loader';
import { useState } from 'react';
import { StaticImage } from '@/components/StaticImage';
import { isValid } from 'zod';
import { logger } from '@/root/libs/utils/logger';
import { PasswordRequirements } from './PasswordRequirements';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
export type TSignUpPasswordFormProps = {
  action: TAuthFormAction;
  errors: string[];
  loading: boolean;
};
export const SignUpPasswordForm = ({
  action,
  errors,
  loading
}: TSignUpPasswordFormProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [pass, setPass] = useState('');
  const toggle = () => setIsPasswordVisible(prev => !prev);
  return <Root className={classNames(styles.Root, styles.SignUpPasswordForm)} onSubmit={async event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    await action(data);
  }} data-sentry-element="Root" data-sentry-component="SignUpPasswordForm" data-sentry-source-file="index.tsx">
			<Field className={classNames(styles.Field, styles.PasswordField)} name='password' data-sentry-element="Field" data-sentry-source-file="index.tsx">
				<Label className={styles.Label} data-sentry-element="Label" data-sentry-source-file="index.tsx">Password</Label>
				<div className={styles.FieldInputWrapper}>
					<Control asChild data-sentry-element="Control" data-sentry-source-file="index.tsx">
						<>
							<input required className={styles.Input} name='password' type={isPasswordVisible ? 'text' : 'password'} onChange={e => setPass(e.target.value)} />
						</>
					</Control>
					<PasswordRequirements password={pass} data-sentry-element="PasswordRequirements" data-sentry-source-file="index.tsx" />
					<div className={styles.FieldInputIcon} onClick={toggle}>
						<StaticImage className={styles.FieldInputIconImage} src='/assets/general/eye.svg' alt='eye-icon' width={24} height={24} data-sentry-element="StaticImage" data-sentry-source-file="index.tsx" />
					</div>
				</div>
				<Field className={classNames(styles.Field, styles.CheckboxField)} name='emailRetrievalConsent' data-sentry-element="Field" data-sentry-source-file="index.tsx">
					<CheckboxRoot className={styles.CheckboxRoot} id='emailRetrievalConsent' data-sentry-element="CheckboxRoot" data-sentry-source-file="index.tsx">
						<CheckboxIndicator className={styles.CheckboxIndicator} data-sentry-element="CheckboxIndicator" data-sentry-source-file="index.tsx">
							<StaticImage src='/assets/check-icon.svg' alt='check-icon' width={16} height={16} data-sentry-element="StaticImage" data-sentry-source-file="index.tsx" />
						</CheckboxIndicator>
					</CheckboxRoot>
					<Label htmlFor='emailRetrievalConsent' className={styles.CheckboxLabel} data-sentry-element="Label" data-sentry-source-file="index.tsx">
						Email me updates on new games, special events, and exclusive offers​
					</Label>
				</Field>
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
			</Field>
			<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
				<div className={styles.ContinueButton}>
					{loading ? <Loader /> : <ButtonCallToAction text='Continue' />}
				</div>
			</Submit>
		</Root>;
};