'use client';

import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
export type AuthTextProps = {
  text: string;
  addClasses?: string[];
  isBold?: boolean;
  color?: 'black' | 'red' | 'gray';
  stylePassed?: any;
  testId?: string;
};
export const AuthText = (props: AuthTextProps) => {
  const text = props.text ?? '';
  const isBold = props.isBold ?? false;
  const color = props.color;
  const addClasses = props.addClasses ?? [];
  const stylePassed = props.stylePassed ?? {};
  return <p className={classNames(styles.Text, {
    [styles.__TextBlack]: !color || color === 'black',
    [styles.__TextRed]: color === 'red',
    [styles.__TextGray]: color === 'gray',
    [styles.__TextBold]: isBold
  }, ...addClasses)} style={{
    color,
    ...stylePassed
  }} data-testid={`profile-text${props.testId ? `-${props.testId}` : ''}`} title={text} data-sentry-component="AuthText" data-sentry-source-file="AuthText.tsx">
			{text}
		</p>;
};