'use client';

import React from 'react';
import { useCaptcha } from '../recaptcha';
import { EntryForm } from './EntryForm';
import { Modal } from '@/components/Modal';
export enum EAuthSteps {
  SIGN_IN_UP_EMAIL = 'SIGN_IN_UP_EMAIL',
  SIGN_UP_PASSWORD = 'SIGN_UP_PASSWORD',
  SIGN_IN_PASSWORD = 'SIGN_IN_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  EMAIL_SENT_FORM = 'EMAIL_SENT_FORM',
  THANK_YOU_FORM = 'THANK_YOU_FORM',
}
export const EntryModal = (props: {
  open: boolean;
  onSetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ctaButtonText: string;
  captchaData: ReturnType<typeof useCaptcha>;
  onLoginSuccess: () => void;
  domain: string;
}) => {
  const [currentStep, setCurrentStep] = React.useState<EAuthSteps>(EAuthSteps.SIGN_IN_UP_EMAIL);
  const [prevStep, setPrevStep] = React.useState<EAuthSteps | null>(null);
  const NO_BACK_BUTTON_STATES = [EAuthSteps.EMAIL_SENT_FORM, EAuthSteps.THANK_YOU_FORM, EAuthSteps.SIGN_IN_UP_EMAIL];
  const onBackButtonClick = () => {
    if (currentStep === EAuthSteps.SIGN_UP_PASSWORD || currentStep === EAuthSteps.SIGN_IN_PASSWORD) {
      setCurrentStep(EAuthSteps.SIGN_IN_UP_EMAIL);
    }
    if (currentStep === EAuthSteps.FORGOT_PASSWORD) {
      setCurrentStep(EAuthSteps.SIGN_IN_PASSWORD);
    }
  };
  return <Modal isBackButtonShown={!NO_BACK_BUTTON_STATES.includes(currentStep)} onBackButtonClick={onBackButtonClick} open={props.open} onSetOpen={props.onSetOpen} ctaButtonText={props.ctaButtonText} content={<EntryForm domain={props.domain} captchaData={props.captchaData} onLoginSuccess={props.onLoginSuccess} currentStep={currentStep} changeCurrentStep={setCurrentStep} />} data-sentry-element="Modal" data-sentry-component="EntryModal" data-sentry-source-file="EntryModal.tsx" />;
};