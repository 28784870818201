'use client';
import { useState, useEffect, useRef, useCallback } from 'react';
import { getOriginUrlForCaptchaIframe } from '@/features/recaptcha/utils';
import { logger } from '@/libs/utils/logger';

// Payment flows, from "@arkadium/eagle-payments-api-client"
export enum CaptchaActionsEnum {
	PurchaseSubscription = 'PurchaseSub',
	PurchaseSubscriptionByEmail = 'PurchaseSubByEmail',
	PurchaseItem = 'PurchaseItem',
}
export enum RECAPTCHA_ACTIONS_CLIENT {
	// User flows, provided by Eagle directly
	SIGN_IN = 'SignIn',
	SIGN_UP = 'SignUp',
	PASSWORD_RESET = 'PasswordReset',
	CONFIRMATION_RESEND = 'ResendConfirmation',
}

export enum RECAPTCHA_MODES {
	CHALLENGE = 'challenge',
}

const RECAPTCHA_ACTIONS_PAYMENTS = CaptchaActionsEnum;

export const RECAPTCHA_ACTIONS = { ...RECAPTCHA_ACTIONS_CLIENT, ...RECAPTCHA_ACTIONS_PAYMENTS };
export type RECAPTCHA_ACTIONS_TYPE = (typeof RECAPTCHA_ACTIONS)[keyof typeof RECAPTCHA_ACTIONS];
export enum RECAPTCHA_POST_MESSAGE_TYPE {
	GET_CAPTCHA_TOKEN = 'GET_CAPTCHA_TOKEN',
	SEND_CAPTCHA_TOKEN = 'SEND_CAPTCHA_TOKEN',
	CHALLENGE_CAPTCHA_TOKEN = 'CHALLENGE_CAPTCHA_TOKEN',
	LOAD_CAPTCHA = 'LOAD_CAPTCHA',
	LOAD_CHALLENGE_CAPTCHA = 'LOAD_CHALLENGE_CAPTCHA',
}
type TRECAPTCHA_POST_MESSAGE = {
	type: RECAPTCHA_POST_MESSAGE_TYPE;
	payload: { key: string; action: RECAPTCHA_ACTIONS_TYPE | null } | string;
};
export const useCaptcha = () => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const iframeURL = getOriginUrlForCaptchaIframe();
	const [isIframeCaptchaLoaded, setIsIframeCaptchaLoaded] = useState<boolean>(false);
	const [showChallengeRecaptcha, setShowChallengeRecaptcha] = useState<boolean>(false);
	const [captchaToken, setCaptchaToken] = useState<string | undefined>(undefined);
	const [captchaTokenError, setCaptchaTokenError] = useState<string | null>(null);
	const [recaptchaAction, setRecaptchaAction] = useState<RECAPTCHA_ACTIONS_TYPE | null>(null);
	const [isCaptchaPending, setIsCaptchaPending] = useState<boolean>(false);
	const postMessageToIframe = useCallback((data: TRECAPTCHA_POST_MESSAGE) => {
		if (iframeRef.current) {
			iframeRef.current?.contentWindow?.postMessage({ ...data }, getOriginUrlForCaptchaIframe());
		}
	}, []);
	const getCaptchaToken = useCallback(
		(action: RECAPTCHA_ACTIONS_TYPE) => {
			setIsCaptchaPending(true);
			const data = {
				type: RECAPTCHA_POST_MESSAGE_TYPE.GET_CAPTCHA_TOKEN,
				payload: {
					key: process.env.RECAPTCHAAPPKEY,
					action,
				},
			};
			logger.debug(iframeRef, 'inside getCaptchaToken');

			postMessageToIframe(data);
		},
		[postMessageToIframe],
	);
	const clearCaptchaData = useCallback(() => {
		setCaptchaToken(undefined);
		setRecaptchaAction(null);
		setShowChallengeRecaptcha(false);
		setIsCaptchaPending(false);
	}, []);

	useEffect(() => {
		const onMessage = (message: MessageEvent) => {
			const event = message.data;
			switch (event.type) {
				case RECAPTCHA_POST_MESSAGE_TYPE.SEND_CAPTCHA_TOKEN:
					setRecaptchaAction(event.payload.action);
					if (event.payload.token) {
						setCaptchaToken(event.payload.token);
					} else {
						setCaptchaTokenError('Captcha loading error');
					}
					setIsCaptchaPending(false);
					break;
				case RECAPTCHA_POST_MESSAGE_TYPE.CHALLENGE_CAPTCHA_TOKEN:
					if (event.payload) {
						setCaptchaToken(event.payload);
					} else {
						setCaptchaTokenError('Captcha loading error');
					}
					setIsCaptchaPending(false);
					break;
				default:
					break;
			}
		};
		window.addEventListener('message', onMessage);

		return () => window.removeEventListener('message', onMessage);
	}, []);

	useEffect(() => {
		if (isIframeCaptchaLoaded) {
			let data = null;
			if (!showChallengeRecaptcha) {
				data = {
					type: RECAPTCHA_POST_MESSAGE_TYPE.LOAD_CAPTCHA,
					payload: process.env.RECAPTCHAAPPKEY,
				};
				// postMessageToIframe(data);
			} else {
				data = {
					type: RECAPTCHA_POST_MESSAGE_TYPE.LOAD_CHALLENGE_CAPTCHA,
					payload: {
						key: process.env.CHALLENGE_CAPTCHA_KEY,
						action: recaptchaAction,
					},
				};
			}

			postMessageToIframe(data);
			// iframeRef?.current?.contentWindow?.postMessage({ ...data }, getOriginUrlForCaptchaIframe());
		}
	}, [recaptchaAction, isIframeCaptchaLoaded, showChallengeRecaptcha, postMessageToIframe]);

	return {
		iframeURL,
		iframeRef,
		setIsIframeCaptchaLoaded,
		getCaptchaToken,
		captchaToken,
		setCaptchaToken,
		recaptchaAction,
		setRecaptchaAction,
		clearCaptchaData,
		showChallengeRecaptcha,
		setShowChallengeRecaptcha,
		isCaptchaPending,
		setIsCaptchaPending,
		captchaTokenError,
	};
};
