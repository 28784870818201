'use client';

import styles from './form.styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import { authUserByEmail, authUserViaFacebook, authUserViaGoogle, checkUserEmail, registerUserViaEmail, requestResetPassword } from '@/libs/api/login';
import { useEffect, useState } from 'react';
import { CaptchaIframe, RECAPTCHA_ACTIONS, useCaptcha } from '@/features/recaptcha';
import { UserApiErrorV1 } from '@/libs/api/eagle-api-wrapper/api-gateway';
import { RECAPTCHA_MODES } from '../recaptcha/hooks/useCaptcha';
import { SignInUpEmailForm } from './SignInUpEmailForm';
import { SignInPasswordForm } from './SignInPasswordForm';
import { ResponseError } from '@/root/types/ResponseError';
import { GoogleLoginDTO, FacebookLoginDTO } from '@/libs/api/login';
import { AuthType } from '@/libs/api/auth';
import { AuthResponsePayloadDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { logger } from '@/libs/utils/logger';
import { EAuthSteps } from './EntryModal';
import { Description, Title } from '@radix-ui/react-dialog';
import { StaticImage } from '@/components/StaticImage';
import { PrivacyPolicyLink } from '@/components/Auth/PrivacyPolicyLink/PrivacyPolicyLink';
import { SignUpPasswordForm } from './SignUpPasswordForm';
import { EmailSentForm } from './EmailSentForm';
import classNames from 'classnames';
import { resendEmail } from '@/root/libs/api/register';
import { ForgotPasswordForm } from './ForgotPasswordForm';
export type TAuthFormAction = (formData: FormData) => Promise<void> | void;
type TEntryFormProps = {
  captchaData: ReturnType<typeof useCaptcha>;
  onLoginSuccess: () => void;
  domain: string;
  currentStep: EAuthSteps;
  changeCurrentStep: (step: EAuthSteps) => void;
};
export enum AuthProvider {
  facebook = 'Facebook',
  google = 'Google',
  arkadium = 'Arkadium',
}
enum Events {
  FacebookAuth = 'update-user-info',
  GoogleAuth = 'auth-google-user',
}
type TAuthProviderAppId = {
  authProviderAppId: string;
};
export interface OAuthUserResponse {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  access_token: string;
}
export type TAuthorizedData = {
  user: OAuthUserResponse;
  eventType: AuthProvider;
};
type SocialLoginInfoType = {
  data: GoogleLoginDTO | (FacebookLoginDTO & TAuthProviderAppId);
  eventType: AuthProvider;
};
export const EntryForm = ({
  captchaData,
  onLoginSuccess,
  domain,
  currentStep,
  changeCurrentStep
}: TEntryFormProps) => {
  const [errors, setErrors] = useState([] as string[]);
  const [errorCode, setErrorCode] = useState<UserApiErrorV1 | null>(null);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailRetrievalConsent, setEmailRetrievalConsent] = useState(false);
  const [captchaMode, setCaptchaMode] = useState<RECAPTCHA_MODES | undefined>(undefined);
  const [eventType, setEventType] = useState<AuthProvider>(AuthProvider.arkadium);
  const [socialUser, setSocialUser] = useState<OAuthUserResponse>();
  const [authType, setAuthType] = useState<AuthType>(AuthType.EmailPassword);
  const [isEmailSentNotification, setIsEmailSentNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const domainWithProtocol = domain.startsWith('http') ? domain : 'https://' + domain;
  const {
    captchaToken,
    captchaTokenError,
    clearCaptchaData,
    getCaptchaToken,
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    showChallengeRecaptcha,
    setShowChallengeRecaptcha
  } = captchaData;
  useEffect(() => {
    if (captchaTokenError) {
      setErrors(errors => [...errors, captchaTokenError]);
    }
  }, [captchaTokenError]);
  useEffect(() => {
    const isChallengeMode = errorCode === UserApiErrorV1.CaptchaScoreIsTooLow;
    if (!isChallengeMode) {
      clearCaptchaData();
    }
    if (errorCode === UserApiErrorV1.UserNotConfirmedError || errorCode === UserApiErrorV1.UserEmailConfirmationCodeIsInvalid) {
      //TODO: handle user confirmation
    }
    setShowChallengeRecaptcha(isChallengeMode);
    if (isChallengeMode) {
      setCaptchaMode(RECAPTCHA_MODES.CHALLENGE);
    }
  }, [errorCode, clearCaptchaData, setShowChallengeRecaptcha]);
  useEffect(() => {
    if (captchaToken) {
      (async () => {
        const {
          request,
          successCallback,
          errorCallback
        } = getRequestMethods();
        const res = await request?.();
        setLoading(false);
        if (res && 'errorCode' in res) {
          errorCallback?.(res);
          return;
        }
        successCallback?.();
        clearCaptchaData();
      })();
    }
  }, [captchaToken]);
  const signInRequest = {
    request: () => authUserByEmail({
      email,
      password,
      captchaToken,
      registrationPlaceUrl: domainWithProtocol,
      ...(captchaMode ? {
        captchaMode
      } : {})
    }),
    successCallback: onLoginSuccess,
    errorCallback: (res: ResponseError) => {
      setErrors(res.message as string[]);
      setErrorCode(res.errorCode);
    }
  };
  const signUpRequest = {
    request: () => registerUserViaEmail({
      email,
      password,
      emailRetrievalConsent,
      captchaToken,
      registrationPlaceUrl: domainWithProtocol,
      ...(captchaMode ? {
        captchaMode
      } : {})
    }),
    successCallback: () => changeCurrentStep(EAuthSteps.EMAIL_SENT_FORM),
    errorCallback: (res: ResponseError) => {
      setErrors(res.message as string[]);
      setErrorCode(res.errorCode);
    }
  };
  const forgotPasswordRequest = {
    request: () =>
    //no app id
    requestResetPassword({
      email,
      registrationPlaceUrl: domainWithProtocol,
      resetPasswordConfirmationFormUrl: domainWithProtocol,
      captchaToken,
      ...(captchaMode ? {
        captchaMode
      } : {})
    }),
    successCallback: () => changeCurrentStep(EAuthSteps.THANK_YOU_FORM),
    errorCallback: (res: ResponseError) => {
      setErrors(res.message as string[]);
      setErrorCode(res.errorCode);
    }
  };
  const emailSentRequest = {
    request: () =>
    // no app id
    resendEmail({
      email,
      authType,
      resendConfirmationFormUrl: domainWithProtocol,
      captchaToken,
      ...(captchaMode ? {
        captchaMode
      } : {})
    }),
    successCallback: () => setIsEmailSentNotification(true),
    errorCallback: (res: ResponseError) => {
      setErrors(res.message as string[]);
      setErrorCode(res.errorCode);
    }
  };
  const getRequestMethods = () => {
    const emptyRequest = {
      request: null,
      successCallback: null,
      errorCallback: null
    };
    switch (currentStep) {
      case EAuthSteps.SIGN_IN_PASSWORD:
        return signInRequest;
      case EAuthSteps.SIGN_UP_PASSWORD:
        return signUpRequest;
      case EAuthSteps.FORGOT_PASSWORD:
        return forgotPasswordRequest;
      case EAuthSteps.EMAIL_SENT_FORM:
        return emailSentRequest;
      case EAuthSteps.THANK_YOU_FORM:
        return emailSentRequest;
      default:
        return emptyRequest;
    }
  };
  const performOAuthLogin = async (loginData: GoogleLoginDTO | FacebookLoginDTO, loginEventType: AuthProvider) => {
    if (loginEventType === AuthProvider.google) {
      return authUserViaGoogle(loginData as GoogleLoginDTO);
    }
    return authUserViaFacebook(loginData as FacebookLoginDTO);
  };
  const onAuthorized = async (data: TAuthorizedData) => {
    if (!data.user) {
      return;
    }

    // login via fb/google with eagle
    if ('id' in data.user) {
      const isGoogle = data.eventType === AuthProvider.google;
      const loginData: (GoogleLoginDTO | FacebookLoginDTO) & TAuthProviderAppId = {
        token: data.user.access_token,
        email: data.user.email ?? null,
        emailRetrievalConsent: false,
        registrationPlaceUrl: domainWithProtocol,
        registrationAppId: new URL(domainWithProtocol).hostname,
        authProviderAppId: isGoogle ? process.env.DEFAULT_GOOGLE_APP_ID as string : '',
        //TODO: switch to dynamic facebookAppId from arena config
        captchaToken,
        ...(captchaMode ? {
          captchaMode
        } : {})
      };
      try {
        const result = await performOAuthLogin(loginData, data.eventType);
        if (result && 'errorCode' in result) {
          setErrorCode(result.errorCode);
          setErrors(result.message as string[]);
          return;
        }
        handleSuccessOAuthLogin((result as AuthResponsePayloadDto).isRegistered, data);
        clearCaptchaData();
      } catch (err: unknown | ResponseError) {
        if ((err as ResponseError).errorCode !== 1023) {
          clearCaptchaData();
        }
        if ((err as ResponseError).errorCode === 1023) {
          setCaptchaMode(RECAPTCHA_MODES.CHALLENGE);
          setShowChallengeRecaptcha(true);
        }

        // EmailDoesNotSpecified
        if ((err as ResponseError).errorCode === 1007) {
          // openRequestEmailForm();
          logger.debug('openRequestEmailForm', err);
          const authenticationType = data.eventType === AuthProvider.google ? AuthType.Google : AuthType.Facebook;
          setAuthType(authenticationType);
        }

        // UserNotConfirmedError
        if ((err as ResponseError).errorCode === 1018) {
          setEmail(email);
          logger.debug('userNotConfirmed', err);
          // dispatch(setLoginStep(ELoginSteps.AFTER_SIGN));
          changeCurrentStep(EAuthSteps.EMAIL_SENT_FORM);
          // dispatch(setLoginPrevStep(ELoginSteps.REQUEST_EMAIL));
        }
      }
    }
  };
  const handleSuccessOAuthLogin = (isUserRegistered: boolean, authorizedData: TAuthorizedData | SocialLoginInfoType) => {
    const authEventType = 'eventType' in authorizedData ? authorizedData.eventType : undefined;
    if (isUserRegistered) {
      // AnalyticsGeneral.registration(caller, authEventType);
      logger.debug('user registered');
    } else {
      logger.debug('user logged in');
      onLoginSuccess();
    }

    // AnalyticsGeneral.login(ActionTypes.LOGIN, caller, authEventType);
  };
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.SIGN_IN_UP_EMAIL && (eventType === AuthProvider.facebook || eventType === AuthProvider.google)) {
      void onAuthorized({
        eventType,
        user: socialUser as OAuthUserResponse
      });
    }
  }, [captchaToken, currentStep, eventType, onAuthorized, socialUser]);
  useEffect(() => {
    window.addEventListener('message', onLoginMessage);
    return () => window.removeEventListener('message', onLoginMessage);
  }, []);
  const onLoginMessage = (message: MessageEvent) => {
    const event = message.data;
    if (!event) {
      return;
    }
    switch (event.action) {
      case Events.FacebookAuth:
        setSocialUser(event.data);
        setEventType(AuthProvider.facebook);
        setLoading(true);
        getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
        break;
      case Events.GoogleAuth:
        setSocialUser(event.user);
        setEventType(AuthProvider.google);
        setLoading(true);
        getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
        break;
      default:
        break;
    }
  };
  const signInUpEmailFormAction = async (formData: FormData) => {
    setErrors([]);
    const data = Object.fromEntries(formData);
    const email = data.email as string;
    setEmail(email);
    setLoading(true);
    const res = await checkUserEmail({
      email
    });
    setLoading(false);
    if (res && 'errorCode' in res) {
      if (res.errorCode === UserApiErrorV1.UserNotFound) {
        changeCurrentStep(EAuthSteps.SIGN_UP_PASSWORD);
        return;
      }
      setErrors(res.message as string[]);
    }
    changeCurrentStep(EAuthSteps.SIGN_IN_PASSWORD);
    return;
  };
  const signInPasswordFormAction = (formData: FormData) => {
    setErrors([]);
    const data = Object.fromEntries(formData);
    const password = data.password as string;
    setPassword(password);
    setLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN); //crucial step to trigger recaptcha token which triggers the eagle request
  };
  const signUpPasswordFormAction = (formData: FormData) => {
    setErrors([]);
    const data = Object.fromEntries(formData);
    const password = data.password as string;
    const emailRetrievalConsent = data.emailRetrievalConsent === 'on';
    setPassword(password);
    setEmailRetrievalConsent(emailRetrievalConsent);
    setLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_UP);
  };
  const forgotPasswordFormAction = (formData: FormData) => {
    setErrors([]);
    const data = Object.fromEntries(formData);
    const email = data.email as string;
    setEmail(email);
    setLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.PASSWORD_RESET);
  };
  const resendEmailFormAction = () => {
    setErrors([]);
    setIsEmailSentNotification(false);
    setLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
  };
  const renderForm = (): React.JSX.Element | null => {
    switch (currentStep) {
      case EAuthSteps.SIGN_IN_UP_EMAIL:
        const descriptionItems = [{
          iconUrl: '/assets/heart.svg',
          iconAlt: 'heart icon',
          text: 'Receive free boosts'
        }, {
          iconUrl: '/assets/message-chat-circle.svg',
          iconAlt: 'chat icon',
          text: 'Be the first to know about new games'
        }, {
          iconUrl: '/assets/trophy.svg',
          iconAlt: 'trophy icon',
          text: 'Save and track your progress'
        }];
        return <>
						<Title className={classNames(modalStyles.Title, styles.SignInUpTitle)}>
							Sign in or sign up
						</Title>
						<Description className={classNames(modalStyles.Description, styles.SignInUpDescription)}>
							{descriptionItems.map((item, index) => <div key={index} className={styles.descriptionItem}>
									<StaticImage width={24} height={24} src={item.iconUrl} alt={item.iconAlt} />
									{item.text}
								</div>)}
						</Description>
						<SignInUpEmailForm loading={loading} action={signInUpEmailFormAction} errors={errors} />
					</>;
      case EAuthSteps.SIGN_UP_PASSWORD:
        return <>
						<Title className={classNames(modalStyles.Title, styles.SignUpPasswordTitle)}>
							Choose your password
						</Title>
						<Description className={classNames(modalStyles.Description, styles.SignUpPasswordDescription)}>
							You are signing up with <span className={modalStyles.strong}>[{email}]</span>
						</Description>
						<SignUpPasswordForm loading={loading} action={signUpPasswordFormAction} errors={errors} />
					</>;
      case EAuthSteps.SIGN_IN_PASSWORD:
        return <>
						<Title className={classNames(modalStyles.Title, styles.SignInPasswordTitle)}>
							Welcome back
						</Title>
						<Description className={classNames(modalStyles.DescriptionPassword, styles.SignInPasswordDescription)}>
							Ready to play? <br />
							Sign in with <span className={modalStyles.strong}>[{email}]</span>
						</Description>
						<SignInPasswordForm loading={loading} action={signInPasswordFormAction} errors={errors} changeCurrentStep={changeCurrentStep} />
					</>;
      case EAuthSteps.FORGOT_PASSWORD:
        return <>
						<Title className={classNames(modalStyles.Title, styles.ForgotPasswordTitle)}>
							Reset your password
						</Title>
						<Description className={classNames(modalStyles.DescriptionPassword, styles.ForgotPasswordDescription)}>
							Enter the email linked to your Arkadium profile to receive a reset link​
						</Description>
						<ForgotPasswordForm email={email} action={forgotPasswordFormAction} errors={errors} loading={loading} />
					</>;
      case EAuthSteps.EMAIL_SENT_FORM:
        return <>
						<Title className={classNames(modalStyles.Title, styles.EmailSentTitle)}>
							You&apos;re almost done​
						</Title>
						<Description className={classNames(styles.EmailSentDescription)}>
							We just sent an email to {email}
						</Description>
						<Description className={classNames(styles.EmailSentDescription)}>
							Follow the instructions to finish signing up.
						</Description>
						<Description className={classNames(styles.EmailSentDescription)}>
							Can’t find the email? Check your spam folder or resend it​
						</Description>
						<EmailSentForm loading={loading} action={resendEmailFormAction} errors={errors} isEmailSentNotification={isEmailSentNotification} />
					</>;
      case EAuthSteps.THANK_YOU_FORM:
        return <>
						<Title className={classNames(modalStyles.Title, styles.ThankYouTitle)}>Thank you</Title>
						<Description className={classNames(styles.ThankYouDescription)}>
							We sent an email to {email} with instructions
						</Description>
						<Description className={classNames(styles.ThankYouDescription)}>
							Can’t find the email? Check your Spam folder or resend it
						</Description>
						<EmailSentForm loading={loading} action={resendEmailFormAction} errors={errors} isEmailSentNotification={isEmailSentNotification} />
					</>;
      default:
        return null;
    }
  };
  const renderCaptcha = () => {
    return <CaptchaIframe isVisible={showChallengeRecaptcha} iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded} data-sentry-element="CaptchaIframe" data-sentry-component="renderCaptcha" data-sentry-source-file="EntryForm.tsx" />;
  };
  return <>
			{!showChallengeRecaptcha ? renderForm() : null}
			{renderCaptcha()}
			<PrivacyPolicyLink currentStep={currentStep} data-sentry-element="PrivacyPolicyLink" data-sentry-source-file="EntryForm.tsx" />
		</>;
};