// import { Api, ApiSet, UserApiErrorV1 } from '@arkadium/eagle-user-client';
import { EmailConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-confirmation.dto';
import { EmailLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-login.dto';
import { EmailRegistrationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-registration.dto';
import { RegisterResponseDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/register-response.dto';
import { ResendConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/resend-confirmation.dto';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { logger } from '../utils/logger';
import { FeatureType, ResponseError } from '@/types/ResponseError';
import { AuthApi } from '@arkadium/eagle-user-client/dist/types/api/v1/auth.api';
import { AuthResponseDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { DEFAULT_AVATAR } from '@/libs/utils/constants';
import { EAGLE_TEST_DATA } from '@/test-utils/mocks/auth/eagle-response-mocks';
import { Api, ApiGateway, ApiGatewayInitParams, UserApiErrorV1 } from './eagle-api-wrapper/api-gateway';
import { AuthToken } from './eagle-api-wrapper/api/v1/auth.api';
import { cookies } from 'next/headers';
import { decryptData } from '../utils/crypto';
import { getEagleToken, setAuthCookie } from './login';
import { UserUpdateRequestDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-update.dto';
import { ChangePasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-password.dto';
import { GoogleLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/google-login.dto';
import { FacebookLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/facebook-login.dto';
import { UserProfileForSocialDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile-for-social.dto';
import { UserCheckEmail } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-check-email.dto';
import { RequstResetPasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/request-reset-password.dto';

type ErrorDetail = { ErrorCode: number; Message: string | Array<string>; Data?: any };
export type ResponseErrorAuth = {
	name: string;
	response: ReadableStream;
	body: {
		details: [ErrorDetail] | ErrorDetail;
		statusCode: number;
	};
};

export enum AuthType {
	EmailPassword = 'EmailPassword',
	Facebook = 'Facebook',
	Google = 'Google',
	HSN = 'HSN',
}

export class EagleLoginProvider {
	public authApi: ApiGateway;

	// public paramsCheck: ApiGatewayInitParams;

	constructor() {
		const params: ApiGatewayInitParams = {
			// 	// Base url of Eagle API
			server: new URL(process.env.EAGLE_USER_API),
		};

		// this.paramsCheck = params;

		this.authApi = new ApiGateway(params);
		this.authApi.getAuthApi(Api.v1).then((api) => {
			api.auth.setSaveCredentialCallback(setAuthCookie);
		});
		// this.authApi.setServer(new URL('https://eagle-user-api-dev.arkadiumhosted.com/'));
		// this.authStateChangedListenerAdd();
		// this.authApi.checkAuthorization();

		// Overwrite since game will use it
		// this.authApi.openWidget = () => sendPostMessage({ type: IframeMessageTypes.OPEN_LOGIN_POPUP });
		// this.authApi.closeWidget = () => sendPostMessage({ type: IframeMessageTypes.CLOSE_LOGIN_POPUP });
	}

	public errorCodeToEnum(errorCode: number): string {
		return (
			Object.keys(UserApiErrorV1).find(
				(key: string) => UserApiErrorV1[key as keyof typeof UserApiErrorV1] === errorCode,
			) ?? ''
		);
	}

	public extractErrorDetails(error: ResponseErrorAuth): ErrorDetail {
		const details = Array.isArray(error.body?.details) ? error.body?.details?.[0] : error.body?.details;
		return details;
	}

	public extractErrorCode(error: ResponseErrorAuth): number {
		const details = this.extractErrorDetails(error);
		return details?.ErrorCode || error.body?.statusCode || 400;
	}

	public extractErrorEmail(error: ResponseErrorAuth): string {
		const details = this.extractErrorDetails(error);
		return details?.Data?.email || '';
	}

	public generateError(data: any, error: ResponseErrorAuth, feature: FeatureType): ResponseError {
		const details = this.extractErrorDetails(error);
		const errorCode = this.extractErrorCode(error);
		const statusCode = error.body?.statusCode || 400;
		logger.error({
			requestData: typeof data === 'string' ? data : JSON.stringify(data, null, 2),
			error: JSON.stringify(error, null, 2),
		});
		try {
			return {
				feature,
				errorCode,
				statusCode,
				message: Array.isArray(details.Message) ? details.Message : [details.Message],
			};
		} catch (e) {
			logger.error('trying to generate error', JSON.stringify(e, null, 2));
			return {
				feature,
				errorCode,
				statusCode,
				message: ['Something went wrong'],
			};
		}
	}

	public async registerViaEmail(data: EmailRegistrationDTO): Promise<RegisterResponseDto | ResponseError> {
		try {
			const api = await this.authApi.getAuthApi(Api.v1);
			const res = await api.auth.registerViaEmail(data);
			return res;
		} catch (err: unknown | ResponseErrorAuth) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.REGISTER);
		}
	}

	public confirmUser = async (data: EmailConfirmationDTO): Promise<void | ResponseError> => {
		try {
			const api = await this.authApi.getAuthApi(Api.v1);
			await api.auth.confirmUser(data);
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.REGISTER);
		}
	};

	public resendConfirmation = async (data: ResendConfirmationDTO): Promise<void | ResponseError> => {
		// data = this.addMockDataToRequest(data);
		logger.debug(data);
		try {
			const api = await this.authApi.getAuthApi(Api.v1);
			await api.auth.resendConfirmation(data);
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.REGISTER);
		}
	};

	public async loginViaEmail(data: EmailLoginDTO): Promise<ResponseError | AuthToken> {
		try {
			const api = await this.authApi.getAuthApi(Api.v1);
			return await api.auth.loginViaEmail(data);
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.LOGIN);
		}
	}

	public loginViaGoogle = async (data: GoogleLoginDTO) => {
		const api = await this.authApi.getAuthApi(Api.v1);

		try {
			const result = await api.auth.loginViaGoogle(data);

			return {
				...result,
				isRegistered: result.isRegistered ?? false,
			};
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.LOGIN);
		}
	};

	public loginViaFacebook = async (data: FacebookLoginDTO) => {
		const api = await this.authApi.getAuthApi(Api.v1);

		try {
			const result = await api.auth.loginViaFacebook(data);

			return result;
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.LOGIN);
		}
	};

	public getUser = async (passedToken?: string): Promise<UserProfileDTO | ResponseError> => {
		const api = await this.authApi.getAuthApi(Api.v1);
		const token = passedToken || (await getEagleToken(FeatureType.PROFILE));
		if ((token as ResponseError).errorCode) {
			return token as ResponseError;
		}

		const res = await api.managment.getUserProfile(token as string);
		if (!res) {
			return this.generateError(
				token,
				{
					body: { statusCode: 404, details: { Message: 'User not found' } },
				} as ResponseErrorAuth,
				FeatureType.PROFILE,
			);
		}
		return {
			...res,
			avatar: res?.avatar || DEFAULT_AVATAR.filename,
		};
	};

	public updateUser = async (data: UserProfileDTO): Promise<UserProfileDTO | ResponseError> => {
		const api = await this.authApi.getAuthApi(Api.v1);
		const token = await getEagleToken(FeatureType.PROFILE);
		if ((token as ResponseError).errorCode) {
			return token as ResponseError;
		}

		try {
			const res = await api.managment.updateUser(data, token as string);
			return res;
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.PROFILE);
		}
	};

	public async changePassword(data: ChangePasswordDTO) {
		const api = await this.authApi.getAuthApi(Api.v1);
		const token = await getEagleToken(FeatureType.PROFILE);

		if ((token as ResponseError).errorCode) {
			return token as ResponseError;
		}
		try {
			const res = await api.auth.changePassword(data, token as string);
			logger.debug('changePassword success');
			return res;
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.PROFILE);
		}
	}

	public async softDeleteUser(): Promise<void | ResponseError> {
		const api = await this.authApi.getAuthApi(Api.v1);
		const token = await getEagleToken(FeatureType.PROFILE);
		if ((token as ResponseError).errorCode) {
			return token as ResponseError;
		}
		return await api.managment.softDelete(token as string);
	}

	public checkUserEmail = async (data: UserCheckEmail): Promise<UserProfileForSocialDto | ResponseError> => {
		const api = await this.authApi.getAuthApi(Api.v1);

		try {
			// @ts-ignore because it's bug on Eagle side. updateUser actually
			// returns Promise<UserProfileDTO> not Promise<void>
			return await api.managment.checkUserEmail(data);
		} catch (err) {
			return this.generateError(data, err as ResponseErrorAuth, FeatureType.LOGIN);
		}
	};

	public requestResetPassword = async (data: RequstResetPasswordDTO) => {
		const api = await this.authApi.getAuthApi(Api.v1);

		try {
			await api.auth.requestResetPassword(data);
		} catch (err) {
			console.error(err);
			throw this.generateError(data, err as ResponseErrorAuth, FeatureType.LOGIN);
		}
	};

	// public logout = () => {
	// 	this.authApi.logout();
	// };

	// public manageErrorsEmailVsPass(res: number): TEagleErrorRegistrationResponse {
	// 	const passwordEnums = ['PasswordTooEasy'];
	// 	const codeEnum = this.errorCodeToEnum(res);
	// 	const message = {
	// 		email: '',
	// 		password: '',
	// 	};

	// 	if (passwordEnums.indexOf(codeEnum) !== -1) {
	// 		message.password = String(res);
	// 	} else {
	// 		message.email = String(res);
	// 	}

	// 	return message;
	// }

	// public authStateChangedListenerAdd = () => {
	// 	this.authApi.addEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
	// };

	// public authStateChangedListenerRemove = () => {
	// 	this.authApi.removeEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
	// };

	// public handleAuthStateChanged = () => {
	// 	this.authStateChangedCounter += 1; // the first time always shows false

	// 	// we need to track the second value
	// 	if (this.authStateChangedCounter === 2) {
	// 		const hasAuthToken = localStorage.getItem('eagle-access-token');

	// 		if (hasAuthToken) {
	// 			this.loadUser().then(this.authStateChangedListenerRemove);
	// 		}
	// 	}
	// };

	// public logout = () => {
	// 	this.authApi.logout();
	// };

	// public changePassword(data: ChangePasswordDTO, successCallback: () => void, errorCallback: (arg: number) => void) {
	// 	if (this.isBypassCaptchaToken()) {
	// 		data.captchaToken = this.BYPASS_CAPTCHA_TOKEN;
	// 	}

	// 	return this.api.auth
	// 		.changePassword(data)
	// 		.then(successCallback)
	// 		.catch((err) => {
	// 			console.debug(err);
	// 			errorCallback(this.extractErrorCode(err));
	// 		});
	// }

	// public loginViaHsn = async (data: HsnLoginDto) => {
	// 	const store = (window as any).STORE;
	// 	const api = await this.authApi.getAuthApi(Api.v1);

	// 	try {
	// 		await api.auth.loginViaHsn(data);
	// 		await this.loadUser();
	// 	} catch (err) {
	// 		const errCode = this.extractErrorCode(err);
	// 		const email = this.extractErrorEmail(err);

	// 		if (errCode === 1018) {
	// 			store.dispatch(
	// 				setLoginModal({
	// 					isOpen: true,
	// 					step: ELoginSteps.AFTER_SIGN,
	// 					email,
	// 				}),
	// 			);
	// 			store.dispatch(setLoginOpen(true));
	// 		}

	// 		AppInsightsAnalytics.trackAppError(err, {
	// 			data: 'loginViaHsn()',
	// 			errorCode: {
	// 				errorCode: errCode,
	// 				errorEmail: email,
	// 				isRegistered: {},
	// 			},
	// 		});
	// 		console.error(err);
	// 		throw errCode;
	// 	}
	// };

	// public loginViaUsat = async (data: UsatLoginDTO) => {
	// 	let res;
	// 	const api = await this.authApi.getAuthApi(Api.v1);

	// 	return api.auth
	// 		.loginViaUsat(data)
	// 		.then(this.loadUser)
	// 		.catch((err) => {
	// 			console.error(err);
	// 			res = {
	// 				errorCode: this.extractErrorCode(err),
	// 				errorEmail: this.extractErrorEmail(err),
	// 				isRegistered: {},
	// 			};

	// 			AppInsightsAnalytics.trackAppError(err, {
	// 				data: 'loginViaUsat()',
	// 				errorCode: res,
	// 			});
	// 		});
	// };

	// public loadUser = (): Promise<EagleUser | void> => {
	// 	const store = (window as any).STORE;
	// 	// in case when we overwrite this variable from iframe for localhost/same domain
	// 	const state = store?.getState?.() || store;

	// 	if (!state) {
	// 		return Promise.resolve();
	// 	}

	// 	if (!state.config.isEagle) {
	// 		return Promise.resolve();
	// 	}

	// 	return this.getUser()
	// 		.then((user) => {
	// 			if (user) {
	// 				sendPostMessage({
	// 					type: IframeMessageTypes.LOGIN_USER,
	// 					payload: user,
	// 				});
	// 				store.dispatch?.(loginUser(user));
	// 				const iframe = document.getElementById('canvas-box') as HTMLIFrameElement;
	// 				const isGameForGameRenderingModule = checkIfSlugInList(
	// 					state.game?.slug,
	// 					state.gameRenderingGamesList,
	// 				);

	// 				iframe?.contentWindow.postMessage(
	// 					{
	// 						type: IframeMessageTypes.UPDATE_LOCAL_STORAGE,
	// 						payload: { ...window.localStorage },
	// 					},
	// 					getOriginUrlForIframe(isGameForGameRenderingModule),
	// 				);
	// 				return user;
	// 			} else {
	// 				sendPostMessage({
	// 					type: IframeMessageTypes.SET_AUTHORIZED_STATUS,
	// 					payload: false,
	// 				});
	// 				store.dispatch?.(setUserAuthStatus(UserAuthStatus.USER_NOT_AUTHORIZED));
	// 			}

	// 			return user;
	// 		})
	// 		.catch((err) => {
	// 			console.error(err);
	// 			sendPostMessage({ type: IframeMessageTypes.LOGOUT_USER });
	// 		});
	// };
	// public confirmEmailChangeUser = async (data: ChangeEmailConfirmDTO): Promise<void> => {
	// 	const api = await this.authApi.getAuthApi(Api.v1);

	// 	try {
	// 		await api.managment.changeEmailConfirm(data);
	// 	} catch (err) {
	// 		console.error(err);
	// 		const errorCode = this.extractErrorCode(err);

	// 		AppInsightsAnalytics.trackAppError(err, {
	// 			data: 'confirmEmailChangeUser()',
	// 			errorCode,
	// 		});
	// 		throw errorCode;
	// 	}
	// };

	// public changePasswordByRecoveryCode = async (data: ConfirmResetPasswordDTO): Promise<void> => {
	// 	const api = await this.authApi.getAuthApi(Api.v1);

	// 	try {
	// 		await api.auth.confirmResetPassword(data);
	// 	} catch (err) {
	// 		const errorCode = this.extractErrorCode(err);

	// 		AppInsightsAnalytics.trackAppError(err, {
	// 			data: 'confirmResetPassword()',
	// 			errorCode,
	// 		});
	// 		throw errorCode;
	// 	}
	// };

	// public async getToken(): Promise<string> {
	// 	return this.authApi.getToken();
	// }

	// public getSessionStorage(): SessionStorage {
	// 	return this.authApi.getSessionStorage();
	// }

	// public getUserFromStore(): EagleUser {
	// 	const store = (window as any).STORE;

	// 	if (isServer) {
	// 		return null;
	// 	}

	// 	return store.getState().user as EagleUser;
	// }

	// public isUserLoggedIn(): boolean {
	// 	if (isServer) {
	// 		return false;
	// 	}

	// 	return this.getSessionStorage().isAuthorised();
	// }

	// private isBypassCaptchaToken = () =>
	// 	this.BYPASS_CAPTCHA_DOMAINS.includes(hostnameToArenaDomain(window.location.hostname));
}

export const EagleLoginService = new EagleLoginProvider();
