import React from 'react';
import { Root, Portal, Overlay, Content, Close, Trigger } from '@radix-ui/react-dialog';
import styles from './styles.module.css';
import { StaticImage } from '../StaticImage';
import classNames from 'classnames';
import { BackButton } from '@/components/BackButton';
export type TBaseModalProps = {
  open: boolean;
  onSetOpen: (open: boolean) => void;
};
type TModalProps = TBaseModalProps & {
  ctaButtonText: string;
  content: React.JSX.Element;
  isBackButtonShown?: boolean;
  onBackButtonClick?: () => void;
};
//TODO: revise react.memo here
const Modal = React.memo(({
  onSetOpen,
  open,
  ctaButtonText,
  isBackButtonShown,
  content,
  onBackButtonClick
}: TModalProps) => <Root open={open} onOpenChange={onSetOpen}>
			<Trigger asChild className={styles.Trigger}>
				<button>{ctaButtonText}</button>
			</Trigger>
			<Portal>
				<Overlay className={styles.Overlay} />

				<Content className={styles.Content}>
					<div className={classNames(styles.TopButtons, {
        [styles.isBackButtonShown]: isBackButtonShown
      })}>
						{isBackButtonShown ? <BackButton addClass={classNames(styles.BackButton)} testId={'modal-back-button'} onClick={onBackButtonClick} /> : null}
						<Close asChild>
							<button className={styles.Close} aria-label='Close'>
								<StaticImage className={styles.CloseIcon} src={'/assets/x-close.svg'} alt={'Close'} width={40} height={40} />
							</button>
						</Close>
					</div>
					<div className={styles.MainContent}>{content}</div>
				</Content>
			</Portal>
		</Root>);
Modal.displayName = 'Modal';
export { Modal };