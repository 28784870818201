import { isServer } from '@/root/libs/utils/isServer';

export const getOriginUrlForCaptchaIframe = (): string => {
	if (isServer) {
		return '';
	}
	const isLocalhost = window.location.hostname === 'localhost';
	if (isLocalhost) {
		return `http://localhost:3000/recaptcha/iframe-captcha.html`;
	}
	return process.env.CDN_CAPTCHA_IFRAME;
};
