'use client';

import { Root, Field, Label, Control, Submit, Message } from '@radix-ui/react-form';
import styles from '../form.styles.module.css';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TAuthFormAction } from '../EntryForm';
import { GoogleAuthButton } from '@/features/auth/GoogleAuthButton';
import { FacebookAuthButton } from '@/features/auth/FacebookAuthButton';
import { AuthError } from '@/components/Auth/AuthError/AuthError';
import classNames from 'classnames';
import { Loader } from '@/components/Loader';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
export type TForgotPasswordFormProps = {
  email: string;
  action: TAuthFormAction;
  errors: string[];
  loading: boolean;
};
export const ForgotPasswordForm = ({
  email,
  action,
  errors,
  loading
}: TForgotPasswordFormProps) => {
  return <Root className={classNames(styles.Root, styles.ForgotPasswordForm)} onSubmit={async event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    await action(data);
  }} data-sentry-element="Root" data-sentry-component="ForgotPasswordForm" data-sentry-source-file="index.tsx">
			<Field className={classNames(styles.Field)} name='email' data-sentry-element="Field" data-sentry-source-file="index.tsx">
				<Label className={styles.Label} data-sentry-element="Label" data-sentry-source-file="index.tsx">Email</Label>
				<Control asChild data-sentry-element="Control" data-sentry-source-file="index.tsx">
					<input required defaultValue={email} className={classNames(styles.Input)} name='email' type='email' placeholder='your.email@mail.com' />
				</Control>
				<Message match='valueMissing' data-sentry-element="Message" data-sentry-source-file="index.tsx">
					<AuthError error='Please enter your email' data-sentry-element="AuthError" data-sentry-source-file="index.tsx" />
				</Message>
				<Message match='typeMismatch' data-sentry-element="Message" data-sentry-source-file="index.tsx">
					<AuthError error='Please provide a valid email' data-sentry-element="AuthError" data-sentry-source-file="index.tsx" />
				</Message>
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
			</Field>
			<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
				<div className={styles.ContinueButton}>
					{loading ? <Loader /> : <ButtonCallToAction text='Reset password' />}
				</div>
			</Submit>
		</Root>;
};