'use client';

import { Root, Submit } from '@radix-ui/react-form';
import styles from '../form.styles.module.css';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TAuthFormAction } from '../EntryForm';
import classNames from 'classnames';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { ContactSupportBlock } from '@/components/Auth/ContactSupportBlock/ContactSupportBlock';
import { Loader } from '@/components/Loader';
export type TEmailSentFormProps = {
  loading: boolean;
  action: TAuthFormAction;
  isEmailSentNotification: boolean;
  errors: string[];
};
export const EmailSentForm = ({
  action,
  isEmailSentNotification,
  errors,
  loading
}: TEmailSentFormProps) => {
  return <>
			<Root className={classNames(styles.Root, styles.EmailSentForm)} action={action} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
					<div className={styles.ContinueButton}>
						{loading ? <Loader /> : <ButtonCallToAction text='Resend email' />}
						<div className={classNames(styles.EmailSentNotification, {
            [styles.visible]: isEmailSentNotification
          })}>
							Email sent
						</div>
					</div>
				</Submit>
			</Root>
			<ContactSupportBlock data-sentry-element="ContactSupportBlock" data-sentry-source-file="index.tsx" />
		</>;
};