'use client';

import styles from '../form.styles.module.css';
import { TAuthFormAction } from '../EntryForm';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { StaticImage } from '@/components/StaticImage';
import { logger } from '@/root/libs/utils/logger';
import Image from 'next/image';
export type TSignUpPasswordFormProps = {
  action: TAuthFormAction;
  errors: string[];
  loading: boolean;
};
type TPassRequirements = {
  text: string;
  isValid: boolean | null;
};
export const PasswordRequirements = ({
  password
}: {
  password: string;
}) => {
  const [passwordRequirements, setPasswordRequirements] = useState<TPassRequirements[]>([{
    text: 'Minimum of 8 characters',
    isValid: null
  }, {
    text: 'One UPPERCASE letter',
    isValid: null
  }]);
  useEffect(() => {
    logger.debug('password', password);
    if (password) {
      if (password.length < 8) {
        setPasswordRequirements(prev => [{
          text: prev[0].text,
          isValid: false
        }, {
          ...prev[1]
        }]);
      } else {
        setPasswordRequirements(prev => [{
          text: prev[0].text,
          isValid: true
        }, {
          ...prev[1]
        }]);
      }
      if (/[A-Z]/.test(password)) {
        setPasswordRequirements(prev => [{
          ...prev[0]
        }, {
          text: prev[1].text,
          isValid: true
        }]);
      } else {
        setPasswordRequirements(prev => [{
          ...prev[0]
        }, {
          text: prev[1].text,
          isValid: false
        }]);
      }
    }
  }, [password]);
  logger.debug(passwordRequirements);
  return <div className={styles.PasswordRequirements} data-sentry-component="PasswordRequirements" data-sentry-source-file="PasswordRequirements.tsx">
			<p>Make sure your password contains:</p>
			{passwordRequirements.map(requirement => <p key={requirement.text} className={classNames(styles.PasswordRequirement, !requirement.isValid && styles.Invalid)}>
					{requirement.text}
					{requirement.isValid !== null ? requirement.isValid ? <Image src={'/assets/check.svg'} alt={'check-icon'} width={16} height={16} className={classNames(styles.CheckIcon, styles.isValid)} /> : <Image src={'/assets/x-invalid-icon.svg'} alt={'x-invalid-icon'} width={16} height={16} className={classNames(styles.CheckIcon)} /> : null}
				</p>)}
		</div>;
};