import { LocalizedLink } from '@/components/Link';
import styles from './styles.module.css';
import { EAuthSteps } from '@/features/auth/EntryModal';
import classNames from 'classnames';
export const PrivacyPolicyLink = ({
  currentStep
}: {
  currentStep: EAuthSteps;
}) => {
  return <LocalizedLink className={classNames(styles.link, {
    [styles.additionalMargin]: currentStep === EAuthSteps.FORGOT_PASSWORD
  })} href='/privacy-policy'
  // onClick={() => AITracks.authPrivacyPolicyClick(currentStep)}
  data-sentry-element="LocalizedLink" data-sentry-component="PrivacyPolicyLink" data-sentry-source-file="PrivacyPolicyLink.tsx">
			Privacy Policy
		</LocalizedLink>;
};