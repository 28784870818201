'use client';

import { Root, Field, Label, Control, Submit } from '@radix-ui/react-form';
import styles from '../form.styles.module.css';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TAuthFormAction } from '../EntryForm';
import classNames from 'classnames';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { EAuthSteps } from '../EntryModal';
import { Loader } from '@/components/Loader';
export type TSignInPasswordFormProps = {
  action: TAuthFormAction;
  errors: string[];
  changeCurrentStep: (step: EAuthSteps) => void;
  loading: boolean;
};
export const SignInPasswordForm = ({
  action,
  errors,
  changeCurrentStep,
  loading
}: TSignInPasswordFormProps) => {
  return <Root className={classNames(styles.Root, styles.SignInPasswordForm)} action={action} data-sentry-element="Root" data-sentry-component="SignInPasswordForm" data-sentry-source-file="index.tsx">
			<Field className={classNames(styles.Field, styles.PasswordField)} name='password' data-sentry-element="Field" data-sentry-source-file="index.tsx">
				<Label className={styles.Label} data-sentry-element="Label" data-sentry-source-file="index.tsx">Password</Label>
				<Control asChild data-sentry-element="Control" data-sentry-source-file="index.tsx">
					<input required className={styles.Input} name='password' type='password' />
				</Control>
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
			</Field>
			<div className={styles.ForgotPasswordButtonWrapper}>
				<ButtonCallToAction addClass={styles.ForgotPasswordButton} text='Forgot password?' styling='text' onClick={() => changeCurrentStep(EAuthSteps.FORGOT_PASSWORD)} data-sentry-element="ButtonCallToAction" data-sentry-source-file="index.tsx" />
			</div>
			<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
				<div className={styles.ContinueButton}>
					{loading ? <Loader /> : <ButtonCallToAction text='Continue' />}
				</div>
			</Submit>
		</Root>;
};